import React, {useState, useEffect} from "react"
import {Container,Button,Navbar,Nav} from 'react-bootstrap';
import { Link, navigate } from "gatsby"
import dateFormat from 'dateformat';
import moment from 'moment';
import VideoImg1 from "../../assests/images/intro_img.jpg"; 
import NavLink from  "../../components/NavLink/Preval_Presentation"; 
import Header from  "../../components/Common/Header"; 
import {config} from '../../components/Common/constant';
import SEO from "../../components/seo"

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";

import PrevalIntro from "../../components/modules/preval_intro"

import { getUser, isBrowser } from "../../services/auth";
import { submitPresentationStatus } from "../../queries/api_links";
import { useContainerContext } from "../../store/ContainerContext";

const PresentationPage = () => {
    var create_present = '', json_parse;
    const context = useContainerContext();
    const { property_data, seller_data } = context.state;
    const { sellerInfoDispatch } = context.actions;

    const [ article_data, setArticleData ] = useState('');
    const [ playVideo, setPlayVideo ] = useState(false);
    const { loading, error, data } = GET_ARTICLE('preval-presentation-intro');

    if(isBrowser()){
        create_present = localStorage.getItem('create_present');  
    }
    json_parse = create_present && JSON.parse(create_present);

    //console.log("json_parse", json_parse);

    const { loading:seller_loading, error:seller_error, data:sellerData } = SELLER_DETAILS_EMAIL(json_parse.seller_email);

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
        sellerInfoDispatch(sellerData && sellerData.sellers[0])
    }, [data, sellerData]);

    const sendStatus = (present_type, present_status, next_link) => {
        //console.log("property_data",property_data);
        var get_property_id = property_data && property_data.id;
        const updateQry = submitPresentationStatus(get_property_id, present_type, present_status, property_data && property_data).then(res => {
          if(res && res.status === 200){
            //console.log("present => res", res);
            navigate(next_link)
          } else{

          }
        });
    }

    var strapi_url = process.env.GATSBY_STRAPI_SRC;

    var logged_user = getUser();

    var valuation_date = seller_data && seller_data.valuation_date;

    //console.log("property_data",property_data);
    
    return <>
    
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

    <div className="pre-wrapper">
      
      <Header />

      <PrevalIntro property_data={property_data} logged_user={logged_user.logged_user_data && logged_user.logged_user_data} seller_email={json_parse.seller_email} />
      {/* contact-wrapper */}
      
      <NavLink tab_name="Intro" prevLink="" nextLink={config.preval_casestudies} />

    </div>

</>

}

export default PresentationPage