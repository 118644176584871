import React, {useEffect, useState}  from "react"
import { useStaticQuery, graphql } from "gatsby"
import dateFormat from 'dateformat';
import moment from 'moment';
import {Container,Navbar,Nav} from 'react-bootstrap';

import VideoModule from "./video-module"

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";

import { useContainerContext } from "../../store/ContainerContext";

import TimeTrackerComp from "./time_tracker";

const Preval_Intro = (props) => {
    var strapi_url = process.env.GATSBY_STRAPI_SRC;
    const context = useContainerContext();
    const { property_data, seller_data } = context.state;
    const { sellerInfoDispatch } = context.actions;

    const [ article_data, setArticleData ] = useState('');
    const [ valuation_date, setValutionDate ] = useState('');
    const [ playVideo, setPlayVideo ] = useState(false);
    const { loading, error, data } = GET_ARTICLE('preval-presentation-intro');
    const { loading:seller_loading, error:seller_error, data:sellerData } = SELLER_DETAILS_EMAIL(props.seller_email);

  //   const data = useStaticQuery(graphql`
  //   query PrevalIntroQuery {      
  //       strapiArticles(URL: {ne: "preval-presentation-intro"}) {
  //           id
  //           strapiId
  //           Title
  //           URL
  //           Pagename
  //           Image {
  //             internal {
  //               description
  //             }
  //           }
  //           Content
  //           Modules {
  //             CTA_1_Label
  //             CTA_1_URL
  //             CTA_2_Label
  //             CTA_2_URL
  //             Title
  //             Content
  //             Upload_Image {
  //               internal {
  //                 description
  //               }
  //             }
  //             Embed_Video_URL
  //           }
  //         }
  //   }
  // `)

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
        sellerInfoDispatch(sellerData && sellerData.sellers[0])
    }, [data, sellerData]);

    useEffect(()=>{
        //seller_data && setValutionDate(moment(seller_data.valuation_date).add(1, 'hour'));
        seller_data && setValutionDate(moment(seller_data.valuation_date));
    }, [seller_data]);

    //console.log("article_data",article_data);

    
    
    //var article_data = data && data.glstrapi.articles[0];

    //console.log("article_data", data.strapiArticles, article_data);

    return <>
        <TimeTrackerComp TabName={'Intro'} />

        <div className="contact-wrapper intro_section">
          <Container>
            <div className="contact-wrap">
                <div className="contact-content">
                    <h2>{article_data && article_data.Modules[0].Title} <span>{valuation_date && moment(valuation_date).format("Do MMMM") }</span> at <span> {valuation_date && dateFormat(valuation_date, "UTC:h:MMtt")}</span></h2>

                    <p class="fig-content">{article_data && HTMLReactParser(article_data.Modules[0].Content)}</p>

                    {/* {article_data && article_data.Modules[0].CTA_1_Label && <a href="javascript:;" className="btn btn-primary" onClick={() => sendStatus('preval', 'Draft', article_data.Modules[0].CTA_1_URL ? article_data.Modules[0].CTA_1_URL : config.preval_casestudies)}>{article_data.Modules[0].CTA_1_Label}</a> }

                    {article_data && article_data.Modules[0].CTA_2_Label && <Link to={article_data.Modules[0].CTA_2_URL ? article_data.Modules[0].CTA_2_URL : 'javascript:;'} className="btn btn-secondary">{article_data.Modules[0].CTA_2_Label}</Link> } */}
                </div>
                {/* contact-content */} 

                <div className="employment-right">
                    <div className={`video-wrap square_video ${props.logged_user && (props.logged_user.pmd_intro_video) ? 'no_img' : ''}`}>

                        {props.logged_user && props.logged_user.pmd_intro_video ? 
                            <VideoModule width={"100%"} height={"100%"} video_url={props.logged_user.pmd_intro_video} playing={false} loop={false} /> 
                        : (
                            <span className="coming_soon">Video Coming Soon</span>
                          ) 
                        }                        
                    </div>
                </div>
                {/* video-wrap */}
            </div>
            {/* contact-wrap */}
          </Container>                     
      </div>
      {/* contact-wrapper */}
    </>
}

export default Preval_Intro